import React, { createContext, useState } from "react";

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [token, setToken] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [alert, setAlert] = useState(false);
  const [selectedCI, setSelectedCI] = useState([]);
  const [user, setUser] = useState("");
  const [part1, setPart1] = useState(false);
  const [part2, setPart2] = useState(false);
  const [part3, setPart3] = useState(false);
  const [part4, setPart4] = useState(false);
  const [part5, setPart5] = useState(false);
  const [part6, setPart6] = useState(false);
  const [part7, setPart7] = useState(false);
  const [part8, setPart8] = useState(false);
  const [status, setStatus] = useState("start");

  const [handshakePro, setHandshakePro] = useState(false);
  const [CI, setCI] = useState([]);
  const [CIE, setCIE] = useState([]);
  const [resume, setResume] = useState(false);
  const [onlinePre, setOnlinePre] = useState([]);
  const [expCI, setExpCI] = useState([]);
  const [proNet, setProNet] = useState([]);
  const [afterGraduate, setAfterGraduate] = useState("");
  const resetHandler = () => {
    setSelectedCI([]);
    setPart1(false);
    setPart2(false);
    setPart3(false);
    setPart4(false);
    setPart5(false);
    setPart6(false);
    setPart7(false);
    setPart8(false);
    setStatus("start");
    setHandshakePro(false);
    setCI([]);
    setCIE([]);
    setResume(false);
    setOnlinePre([]);
    setExpCI([]);
    setProNet([]);
    setAfterGraduate([]);
  };
  return (
    <DataContext.Provider
      value={{
        alert,
        setAlert,
        token,
        setToken,
        errMsg,
        setErrMsg,
        selectedCI,
        setSelectedCI,
        user,
        setUser,
        part1,
        setPart1,
        part2,
        setPart2,
        part3,
        setPart3,
        part4,
        setPart4,
        part5,
        setPart5,
        part6,
        setPart6,
        part7,
        setPart7,
        part8,
        setPart8,
        handshakePro,
        setHandshakePro,
        CI,
        setCI,
        CIE,
        setCIE,
        resume,
        setResume,
        onlinePre,
        setOnlinePre,
        expCI,
        setExpCI,
        proNet,
        setProNet,
        afterGraduate,
        setAfterGraduate,
        status,
        setStatus,
        resetHandler,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
