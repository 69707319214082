import React from "react";

const Tabs = (props) => {
  return (
    <div className={props.className} id={props.id} role="tabpanel">
      {props.children}
    </div>
  );
};

export default Tabs;
