import React, { useRef, useContext } from "react";
import { CSSTransition } from "react-transition-group";
import MilestoneCompleteMsg from "./milestoneCompletedMsg";
import { scrollToRef, eventLog } from "../Shared/helperFunctions";
import axios from "axios";
import { DataContext } from "../Shared/context";

const OnlinePresence = (props) => {
  const data = useContext(DataContext);
  const cardRef = useRef(null);
  const {
    onlinePre,
    setOnlinePre,
    part5: completed,
    part4: prevCompleted,
    user: userId,
    token,
  } = data;
  // let prevCompleted = props.formStatus.part4.completed;
  // let completed = props.formStatus.part5.completed;
  const inputHandler = (e) => {
    if (onlinePre.includes(e.target.id)) {
      let updateonlinePre = [];
      updateonlinePre = onlinePre.filter((element) => {
        return e.target.id !== element;
      });
      setOnlinePre(updateonlinePre);
    } else {
      setOnlinePre(onlinePre.concat(e.target.id));
    }
  };
  // console.log(onlinePre, "Online Presence");
  const submitData = () => {
    axios
      .get(
        process.env.REACT_APP_SUBMIT_URL +
          "dev/sethistory?user=" +
          `${data.user}` +
          "&index=5&values=" +
          `${onlinePre}` +
          "&type=add",
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        // console.log("save5", JSON.parse(res.data));
        scrollToRef(cardRef);
        data.setPart5(true);
        // setTimeout(() => props.clicked(5), 1000);
        // submitHandler(props, 5, cardRef);
        // console.log(JSON.parse(res.data).responseData.data_value);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const resetData = () => {
    axios
      .get(
        process.env.REACT_APP_RESET_URL +
          "dev/sethistory?user=" +
          `${data.user}` +
          "&index=5&type=reset",
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        // console.log("reset5", JSON.parse(res.data));
        data.setPart5(false);
        data.setOnlinePre([]);
        // submitHandler(props, 5, cardRef, "reset");
        // console.log(JSON.parse(res.data).responseData.data_value);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div
      className={
        "card career-milestones--card " + (completed ? "completed" : "")
      }
      id="Built_Online_Presence"
      ref={cardRef}
    >
      <div
        className={
          "card-header " +
          (props.show["showPart5"] ? "" : "collapsed ") +
          (completed ? "completed" : "")
        }
        role="button"
        data-toggle="collapse"
        data-target="#milestone-5"
        aria-expanded="true"
        onClick={() => props.clicked(5)}
      >
        Have you built an online presence?
      </div>
      <CSSTransition
        in={props.show["showPart5"]}
        timeout={500}
        classNames="item"
        unmountOnExit
      >
        <div
          id="milestone-5"
          // className={"collapse " + (props.show["showPart5"] ? "show" : "")}
          data-parent="#careerMilestones"
        >
          <div className="card-body">
            {completed ? null : (
              <div className="status--not">
                <p>
                  Networking can increase the odds for you to land that ideal
                  job or internship. Take advantage of at least one of these
                  chances to build that professional online presence and
                  complete this milestone.
                </p>

                <div className="checkbox--custom form-group">
                  <input
                    type="checkbox"
                    disabled={prevCompleted ? !"disabled" : "disabled"}
                    className="checkbox--custom-input"
                    id="milestone-5-check-1"
                    name="milestone-5-checkbox"
                    checked={onlinePre.includes("milestone-5-check-1")}
                    onChange={inputHandler}
                  />
                  <label
                    className="checkbox--custom-label"
                    htmlFor="milestone-5-check-1"
                  >
                    <p>
                      <strong>LinkedIn</strong> <br />
                      Build out your LinkedIn profile to begin establishing your
                      professional brand.{" "}
                      <a
                        href="https://university.linkedin.com/content/dam/university/global/en_US/site/pdf/TipSheet_BuildingaGreatProfile.pdf"
                        target="_blank"
                        onClick={(ev) => {
                          // ev.preventDefault();
                          eventLog(axios, userId, "5-LinkedIn");
                        }}
                        rel="noopener noreferrer"
                        className="colored-link"
                      >
                        Use the LinkedIn Profile Checklist to get started.
                      </a>{" "}
                    </p>
                  </label>
                </div>

                <div className="checkbox--custom form-group">
                  <input
                    type="checkbox"
                    disabled={prevCompleted ? !"disabled" : "disabled"}
                    className="checkbox--custom-input"
                    id="milestone-5-check-2"
                    name="milestone-5-checkbox"
                    onChange={inputHandler}
                    checked={onlinePre.includes("milestone-5-check-2")}
                  />
                  <label
                    className="checkbox--custom-label"
                    htmlFor="milestone-5-check-2"
                  >
                    <p>
                      <strong>Professional Social Media</strong> <br />
                      Your social media can speak volumes about you - make sure
                      it is saying all the right things to future employers.
                      Read, watch videos, or listen to podcasts to{" "}
                      <a
                        href="https://career.asu.edu/give-your-online-presence-some-spark"
                        target="_blank"
                        onClick={(ev) => {
                          // ev.preventDefault();
                          eventLog(
                            axios,
                            userId,
                            "5-Professional-Social-Media"
                          );
                        }}
                        rel="noopener noreferrer"
                        className="colored-link"
                      >
                        learn best practices.
                      </a>
                    </p>
                  </label>
                </div>

                <div className="checkbox--custom form-group">
                  <input
                    type="checkbox"
                    disabled={prevCompleted ? !"disabled" : "disabled"}
                    className="checkbox--custom-input"
                    id="milestone-5-check-3"
                    name="milestone-5-checkbox"
                    onChange={inputHandler}
                    checked={onlinePre.includes("milestone-5-check-3")}
                  />
                  <label
                    className="checkbox--custom-label"
                    htmlFor="milestone-5-check-3"
                  >
                    <p>
                      <strong>Digital Portfolio</strong> <br />
                      ASU offers you an{" "}
                      <a
                        href="https://career.asu.edu/conceptualizing-digital-portfolio-any-industry"
                        target="_blank"
                        onClick={(ev) => {
                          // ev.preventDefault();
                          eventLog(axios, userId, "5-Digital-Portfolio");
                        }}
                        rel="noopener noreferrer"
                        className="colored-link"
                      >
                        online portfolio maker
                      </a>{" "}
                      that remains available even after you graduate. Track and
                      highlight your skills, experience and completed work all
                      in one place.
                    </p>
                  </label>
                </div>

                <button
                  type="button"
                  disabled={
                    prevCompleted && onlinePre.length !== 0
                      ? !"disabled"
                      : "disabled"
                  }
                  onClick={submitData}
                  className="btn btn-secondary btn-rounded complete_milestone_btn"
                >
                  <span className="flaticon-tick-1 icon"></span> I'm done!
                </button>
              </div>
            )}

            {completed ? <MilestoneCompleteMsg submit={resetData} /> : null}
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default OnlinePresence;
