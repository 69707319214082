import React, { useRef, useContext } from "react";
import MilestoneCompleteMsg from "./milestoneCompletedMsg";
import { scrollToRef, eventLog } from "../Shared/helperFunctions";
import { CSSTransition } from "react-transition-group";
import axios from "axios";
import { DataContext } from "../Shared/context";

const ExperienceWithCI = (props) => {
  // let prevCompleted = props.formStatus.part5.completed;
  // let completed = props.formStatus.part6.completed;
  const cardRef = useRef(null);
  const data = useContext(DataContext);
  const {
    expCI,
    setExpCI,
    part6: completed,
    part5: prevCompleted,
    user: userId,
    token,
  } = data;
  const inputHandler = (e) => {
    if (expCI.includes(e.target.id)) {
      let updateexpCI = [];
      updateexpCI = expCI.filter((element) => {
        return e.target.id !== element;
      });
      setExpCI(updateexpCI);
    } else {
      setExpCI(expCI.concat(e.target.id));
    }
  };
  // console.log(expCI, "expCI");
  const submitData = () => {
    axios
      .get(
        process.env.REACT_APP_SUBMIT_URL +
          "dev/sethistory?user=" +
          `${data.user}` +
          "&index=6&values=" +
          `${expCI}` +
          "&type=add",
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        // console.log("save6", JSON.parse(res.data));
        scrollToRef(cardRef);
        data.setPart6(true);
        // setTimeout(() => props.clicked(6), 1000);
        // submitHandler(props, 6, cardRef);
        // console.log(JSON.parse(res.data).responseData.data_value);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const resetData = () => {
    axios
      .get(
        process.env.REACT_APP_RESET_URL +
          "dev/sethistory?user=" +
          `${data.user}` +
          "&index=6&type=reset",
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        // console.log("reset6", JSON.parse(res.data));
        data.setPart6(false);
        data.setExpCI([]);
        // submitHandler(props, 6, cardRef, "reset");
        // console.log(JSON.parse(res.data).responseData.data_value);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div
      className={
        "card career-milestones--card " + (completed ? "completed" : "")
      }
      id="Experience_With_Career_Interest"
      ref={cardRef}
    >
      <div
        className={
          "card-header " +
          (props.show["showPart6"] ? "" : "collapsed ") +
          (completed ? "completed" : "")
        }
        role="button"
        data-toggle="collapse"
        data-target="#milestone-6"
        aria-expanded="true"
        onClick={() => props.clicked(6)}
      >
        Do you have experience with your career interest?
      </div>
      <CSSTransition
        in={props.show["showPart6"]}
        timeout={500}
        classNames="item"
        unmountOnExit
      >
        <div
          id="milestone-6"
          // className={"collapse " + (props.show["showPart6"] ? "show" : "")}
          data-parent="#careerMilestones"
        >
          <div className="card-body">
            {completed ? null : (
              <div className="status--not">
                <p>
                  There are several ways to gain experience in the career you
                  are interested in. Take advantage of one of the following
                  options to complete this milestone.
                </p>

                <div className="checkbox--custom form-group">
                  <input
                    disabled={prevCompleted ? !"disabled" : "disabled"}
                    type="checkbox"
                    className="checkbox--custom-input"
                    id="milestone-6-check-1"
                    name="milestone-6-checkbox"
                    onChange={inputHandler}
                    checked={expCI.includes("milestone-6-check-1")}
                  />
                  <label
                    className="checkbox--custom-label"
                    htmlFor="milestone-6-check-1"
                  >
                    <p>
                      <strong>Undergraduate Research</strong> <br />
                      Opportunities to take part in hands-on research in your
                      field of study are all around you. Grab your lab coat,
                      microphone, paint brush, or whatever sparks your passion
                      and let's find your match.{" "}
                      <a
                        href="https://provost.asu.edu/uresearch"
                        target="_blank"
                        onClick={(ev) => {
                          // ev.preventDefault();
                          eventLog(axios, userId, "6-Undergraduate-Research");
                        }}
                        rel="noopener noreferrer"
                        className="colored-link ml-1"
                      >
                        Learn More
                      </a>
                    </p>
                  </label>
                </div>

                <div className="checkbox--custom form-group">
                  <input
                    disabled={prevCompleted ? !"disabled" : "disabled"}
                    type="checkbox"
                    className="checkbox--custom-input"
                    id="milestone-6-check-2"
                    name="milestone-6-checkbox"
                    onChange={inputHandler}
                    checked={expCI.includes("milestone-6-check-2")}
                  />
                  <label
                    className="checkbox--custom-label"
                    htmlFor="milestone-6-check-2"
                  >
                    <p>
                      <strong>Student Employment</strong> <br />
                      Student employment is an excellent way to build your
                      resume, develop professional skills and also build your
                      connections across campus.{" "}
                      <a
                        href="https://students.asu.edu/employment/search"
                        target="_blank"
                        onClick={(ev) => {
                          // ev.preventDefault();
                          eventLog(axios, userId, "6-Student-Employment");
                        }}
                        rel="noopener noreferrer"
                        className="colored-link ml-1"
                      >
                        Learn More
                      </a>
                    </p>
                  </label>
                </div>

                <div className="checkbox--custom form-group">
                  <input
                    type="checkbox"
                    disabled={prevCompleted ? !"disabled" : "disabled"}
                    className="checkbox--custom-input"
                    id="milestone-6-check-3"
                    name="milestone-6-checkbox"
                    onChange={inputHandler}
                    checked={expCI.includes("milestone-6-check-3")}
                  />
                  <label
                    className="checkbox--custom-label"
                    htmlFor="milestone-6-check-3"
                  >
                    <p>
                      <strong>Volunteer Match</strong> <br />
                      Building your resume is all about buidling meaningful
                      experiences in your career interest area. Volunteer work
                      is a great way to do this. Its experience you can feel
                      good about!{" "}
                      <a
                        href="https://changemaker.asu.edu/serve"
                        target="_blank"
                        onClick={(ev) => {
                          // ev.preventDefault();
                          eventLog(axios, userId, "6-Volunteer-Match");
                        }}
                        rel="noopener noreferrer"
                        className="colored-link ml-1"
                      >
                        Learn More
                      </a>
                    </p>
                  </label>
                </div>

                <div className="checkbox--custom form-group">
                  <input
                    type="checkbox"
                    disabled={prevCompleted ? !"disabled" : "disabled"}
                    className="checkbox--custom-input"
                    id="milestone-6-check-4"
                    name="milestone-6-checkbox"
                    onChange={inputHandler}
                    checked={expCI.includes("milestone-6-check-4")}
                  />
                  <label
                    className="checkbox--custom-label"
                    htmlFor="milestone-6-check-4"
                  >
                    <p>
                      <strong>Study Abroad</strong> <br />
                      The benefits of studying abroad are endless and only
                      enrich your experience as a Sun Devil. Stand out from the
                      crowd once you graduate by adding study abroad to your
                      resume and prove you can thrive in a global environment.{" "}
                      <a
                        href="https://mystudyabroad.asu.edu/"
                        target="_blank"
                        onClick={(ev) => {
                          // ev.preventDefault();
                          eventLog(axios, userId, "6-Study-Abroad");
                        }}
                        rel="noopener noreferrer"
                        className="colored-link ml-1"
                      >
                        Learn More
                      </a>
                    </p>
                  </label>
                </div>

                <div className="checkbox--custom form-group">
                  <input
                    type="checkbox"
                    disabled={prevCompleted ? !"disabled" : "disabled"}
                    className="checkbox--custom-input"
                    id="milestone-6-check-5"
                    name="milestone-6-checkbox"
                    onChange={inputHandler}
                    checked={expCI.includes("milestone-6-check-5")}
                  />
                  <label
                    className="checkbox--custom-label"
                    htmlFor="milestone-6-check-5"
                  >
                    <p>
                      <strong>Internships</strong> <br />
                      Want real-life experience in your chosen field before you
                      graduate? Your internship might open the door to future
                      employment, gives you something to add to your resume and
                      talk about in interviews, plus allows you to reaffirm your
                      career interests.{" "}
                      <a
                        href="https://career.asu.edu/internships-learning-doing"
                        target="_blank"
                        onClick={(ev) => {
                          // ev.preventDefault();
                          eventLog(axios, userId, "6-Internships");
                        }}
                        rel="noopener noreferrer"
                        className="colored-link ml-1"
                      >
                        Learn More
                      </a>
                    </p>
                  </label>
                </div>

                <button
                  type="button"
                  disabled={
                    prevCompleted && expCI.length !== 0
                      ? !"disabled"
                      : "disabled"
                  }
                  onClick={submitData}
                  className="btn btn-secondary btn-rounded complete_milestone_btn"
                >
                  <span className="flaticon-tick-1 icon"></span> Complete
                  Milestone
                </button>
              </div>
            )}

            {completed ? <MilestoneCompleteMsg submit={resetData} /> : null}
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default ExperienceWithCI;
