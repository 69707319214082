import React, { useRef, useState, useEffect, useContext } from "react";
import axios from "axios";
import MilestoneCompleteMsg from "./milestoneCompletedMsg";
import { scrollToRef, eventLog } from "../Shared/helperFunctions";
import { CSSTransition } from "react-transition-group";
import { DataContext } from "../Shared/context";

const CarrerInterest = (props) => {
  const data = useContext(DataContext);
  const cardRef = useRef(null);
  const {
    CI,
    setCI,
    part2: completed,
    part1: prevCompleted,
    user: userId,
    token,
    setAlert,
  } = data;
  const [maxRes, setMaxRes] = useState(false);

  const inputHandler = (e) => {
    if (CI.includes(e.target.id)) {
      let updateCI = [];
      updateCI = CI.filter((element) => {
        return e.target.id !== element;
      });
      setCI(updateCI);
      // console.log(updateCI, "update");
    } else if (CI.length >= 3) {
      setMaxRes(true);
    } else {
      setCI(CI.concat(e.target.id));
      setMaxRes(false);
    }
    // console.log(CI, "array");
  };
  const submitButton = () => {
    if (CI.length !== 0) {
      data.setSelectedCI(CI);
      submitData();
    } else {
      alert("Please Select Your Interest first");
    }
  };
  // console.log(CI, "array");
  useEffect(() => {
    setMaxRes(false);
  }, [maxRes]);
  const submitData = () => {
    axios
      .get(
        process.env.REACT_APP_SUBMIT_URL +
          "dev/sethistory?user=" +
          `${data.user}` +
          "&index=2&values=" +
          `${CI}` +
          "&type=add",
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        // console.log("save2", JSON.parse(res.data));
        scrollToRef(cardRef);
        data.setPart2(true);
        // setTimeout(() => props.clicked(2), 1000);
        // submitHandler(props, 2, cardRef);
        // console.log(JSON.parse(res.data).responseData.data_value);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const resetData = () => {
    axios
      .get(
        process.env.REACT_APP_RESET_URL +
          "dev/sethistory?user=" +
          `${data.user}` +
          "&index=2&type=reset",
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then(() =>
        axios.get(
          process.env.REACT_APP_RESET_URL +
            "dev/sethistory?user=" +
            `${data.user}` +
            "&index=3&type=reset"
        )
      )
      .then((res) => {
        // console.log("reset2", JSON.parse(res.data));
        data.setPart2(false);
        data.setPart3(false);
        if (data.part3) {
          setAlert(true);
        }
        data.setCI([]);
        data.setSelectedCI([]);
        // submitHandler(props, 2, cardRef, "reset");
        // console.log(JSON.parse(res.data).responseData.data_value);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div
      className={
        "card career-milestones--card " + (completed ? "completed" : "")
      }
      id="Career_Interests_Card"
      ref={cardRef}
    >
      {maxRes ? alert("You can only Choose upto 3 Interest(s). ") : null}
      <div
        className={
          "card-header " +
          (props.show["showPart2"] ? "" : "collapsed ") +
          (completed ? "completed" : "")
        }
        role="button"
        data-toggle="collapse"
        data-target="#milestone-2"
        aria-expanded="true"
        onClick={() => props.clicked(2)}
      >
        {/* <!-- What are your Career Interests?  <span className=" career-milestones--status alerted"><img src="/img/alert-icon-maroon.jpg" width="35"> </span> --> */}
        What are your Career Interests?
      </div>
      <CSSTransition
        in={props.show["showPart2"]}
        timeout={500}
        classNames="item"
        unmountOnExit
      >
        <div
          id="milestone-2"
          // className={"collapse " + (props.show["showPart2"] ? "show" : "")}
          data-parent="#careerMilestones"
        >
          <div className="card-body">
            {completed ? null : (
              <div className="status--not">
                {/* <p>
                  Select up to 3 career interest(s). Your decision will be
                  reflected in future steps, but don't worry, you can always
                  come back and change your mind.
                </p> */}
                <div className="form-group no-checkbox">
                  <p>
                    Begin personalizing your career milestones by selecting up
                    to 3 career interests. Don't worry we know your interests
                    may change as you begin to explore. You can always come back
                    and adjust your interests. Not sure what to choose just yet?
                    Check out this resource for more tips on how to explore and
                    nail down your options.{" "}
                    <a
                      href="https://career.asu.edu/sites/default/files/handouts/cic_-_still_exploring.pdf"
                      onClick={(ev) => {
                        // ev.preventDefault();
                        eventLog(axios, userId, "2-Still-Exploring");
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="colored-link ml-1"
                    >
                      Learn more
                    </a>
                  </p>
                </div>
                <div className="checkbox--custom form-group">
                  <input
                    type="checkbox"
                    disabled={prevCompleted ? !"disabled" : "disabled"}
                    className="checkbox--custom-input"
                    id="career-interests-1"
                    checked={CI.includes("career-interests-1")}
                    onChange={inputHandler}
                  />
                  <label
                    className="checkbox--custom-label"
                    htmlFor="career-interests-1"
                  >
                    <p>
                      <strong>Arts, Design & Performance</strong> <br />
                      Are you imaginative? Have you been called an
                      unconventional thinker? Do you enjoy sharing your artistic
                      expression with others?{" "}
                      <a
                        href="https://career.asu.edu/arts-design-and-performance"
                        target="_blank"
                        onClick={(ev) => {
                          // ev.preventDefault();
                          eventLog(axios, userId, "2-Arts-Design-Performance");
                        }}
                        rel="noopener noreferrer"
                        className="colored-link ml-1"
                      >
                        Learn more
                      </a>
                    </p>
                  </label>
                </div>

                <div className="checkbox--custom form-group">
                  <input
                    type="checkbox"
                    disabled={prevCompleted ? !"disabled" : "disabled"}
                    className="checkbox--custom-input"
                    id="career-interests-2"
                    checked={CI.includes("career-interests-2")}
                    onChange={inputHandler}
                  />
                  <label
                    className="checkbox--custom-label"
                    htmlFor="career-interests-2"
                  >
                    <p>
                      <strong>Business</strong> <br />
                      Are you driven? Do you seek to solve complex
                      business-related issues in the non-profit, corporate or
                      government sectors?{" "}
                      <a
                        href="https://career.asu.edu/business"
                        target="_blank"
                        onClick={(ev) => {
                          // ev.preventDefault();
                          eventLog(axios, userId, "2-Business");
                        }}
                        rel="noopener noreferrer"
                        className="colored-link ml-1"
                      >
                        Learn more
                      </a>
                    </p>
                  </label>
                </div>

                <div className="checkbox--custom form-group">
                  <input
                    type="checkbox"
                    disabled={prevCompleted ? !"disabled" : "disabled"}
                    className="checkbox--custom-input"
                    id="career-interests-3"
                    checked={CI.includes("career-interests-3")}
                    onChange={inputHandler}
                  />
                  <label
                    className="checkbox--custom-label"
                    htmlFor="career-interests-3"
                  >
                    <p>
                      <strong>Communications & Media</strong> <br />
                      Do you enjoy interacting with and listening to others? Do
                      you enjoy telling a story through print or social media,
                      television, broadcasting or advertising?{" "}
                      <a
                        href="https://career.asu.edu/communications-and-media"
                        target="_blank"
                        onClick={(ev) => {
                          // ev.preventDefault();
                          eventLog(axios, userId, "2-Communications-Media");
                        }}
                        rel="noopener noreferrer"
                        className="colored-link ml-1"
                      >
                        Learn more
                      </a>
                    </p>
                  </label>
                </div>

                <div className="checkbox--custom form-group">
                  <input
                    type="checkbox"
                    disabled={prevCompleted ? !"disabled" : "disabled"}
                    className="checkbox--custom-input"
                    id="career-interests-4"
                    checked={CI.includes("career-interests-4")}
                    onChange={inputHandler}
                  />
                  <label
                    className="checkbox--custom-label"
                    htmlFor="career-interests-4"
                  >
                    <p>
                      <strong>Education</strong> <br />
                      Do you consider yourself to be a strong communicator? Do
                      you enjoy guiding others to learn new things?{" "}
                      <a
                        href="https://career.asu.edu/education"
                        target="_blank"
                        onClick={(ev) => {
                          // ev.preventDefault();
                          eventLog(axios, userId, "2-Education");
                        }}
                        rel="noopener noreferrer"
                        className="colored-link ml-1"
                      >
                        Learn more
                      </a>
                    </p>
                  </label>
                </div>

                <div className="checkbox--custom form-group">
                  <input
                    disabled={prevCompleted ? !"disabled" : "disabled"}
                    type="checkbox"
                    className="checkbox--custom-input"
                    id="career-interests-5"
                    checked={CI.includes("career-interests-5")}
                    onChange={inputHandler}
                  />
                  <label
                    className="checkbox--custom-label"
                    htmlFor="career-interests-5"
                  >
                    <p>
                      <strong>Entrepreneurship</strong> <br />
                      Interested in starting a business or solving a social
                      issue with your innovative ideas? Have you developed a
                      product or service that addresses a need?{" "}
                      <a
                        href="https://career.asu.edu/entrepreneurship"
                        target="_blank"
                        onClick={(ev) => {
                          // ev.preventDefault();
                          eventLog(axios, userId, "2-Entrepreneurship");
                        }}
                        rel="noopener noreferrer"
                        className="colored-link ml-1"
                      >
                        Learn more
                      </a>
                    </p>
                  </label>
                </div>

                <div className="checkbox--custom form-group">
                  <input
                    disabled={prevCompleted ? !"disabled" : "disabled"}
                    type="checkbox"
                    className="checkbox--custom-input"
                    id="career-interests-6"
                    checked={CI.includes("career-interests-6")}
                    onChange={inputHandler}
                  />
                  <label
                    className="checkbox--custom-label"
                    htmlFor="career-interests-6"
                  >
                    <p>
                      <strong>Health & Wellness</strong> <br />
                      Do you find value in improving the health and well-being
                      of others? Do you find joy in helping others make positive
                      changes in their lives?{" "}
                      <a
                        href="https://career.asu.edu/health-and-wellness"
                        target="_blank"
                        onClick={(ev) => {
                          // ev.preventDefault();
                          eventLog(axios, userId, "2-Health-Wellness");
                        }}
                        rel="noopener noreferrer"
                        className="colored-link ml-1"
                      >
                        Learn more
                      </a>
                    </p>
                  </label>
                </div>

                <div className="checkbox--custom form-group">
                  <input
                    type="checkbox"
                    disabled={prevCompleted ? !"disabled" : "disabled"}
                    className="checkbox--custom-input"
                    id="career-interests-7"
                    checked={CI.includes("career-interests-7")}
                    onChange={inputHandler}
                  />
                  <label
                    className="checkbox--custom-label"
                    htmlFor="career-interests-7"
                  >
                    <p>
                      <strong>Public, Social & Human Services</strong> <br />
                      Do you find yourself connecting people to resources that
                      will improve their lives? Are you engaged in the community
                      and want to center your work around a cause you feel
                      strongly about?{" "}
                      <a
                        href="https://career.asu.edu/public-social-and-human-services"
                        target="_blank"
                        onClick={(ev) => {
                          // ev.preventDefault();
                          eventLog(
                            axios,
                            userId,
                            "2-Public-Social-Human-Services"
                          );
                        }}
                        rel="noopener noreferrer"
                        className="colored-link ml-1"
                      >
                        Learn more
                      </a>
                    </p>
                  </label>
                </div>

                <div className="checkbox--custom form-group">
                  <input
                    type="checkbox"
                    disabled={prevCompleted ? !"disabled" : "disabled"}
                    className="checkbox--custom-input"
                    id="career-interests-8"
                    checked={CI.includes("career-interests-8")}
                    onChange={inputHandler}
                  />
                  <label
                    className="checkbox--custom-label"
                    htmlFor="career-interests-8"
                  >
                    <p>
                      <strong>
                        Science, Technology, Engineering & Mathematics
                      </strong>{" "}
                      <br />
                      Do you enjoy solving abstract problems? Have strong math,
                      science, and analytical abilities?{" "}
                      <a
                        href="https://career.asu.edu/stem"
                        target="_blank"
                        onClick={(ev) => {
                          // ev.preventDefault();
                          eventLog(
                            axios,
                            userId,
                            "2-Science-Technology-Engineering-Mathematics"
                          );
                        }}
                        rel="noopener noreferrer"
                        className="colored-link ml-1"
                      >
                        Learn more
                      </a>
                    </p>
                  </label>
                </div>

                <div className="checkbox--custom form-group">
                  <input
                    type="checkbox"
                    disabled={prevCompleted ? !"disabled" : "disabled"}
                    className="checkbox--custom-input"
                    id="career-interests-9"
                    checked={CI.includes("career-interests-9")}
                    onChange={inputHandler}
                  />
                  <label
                    className="checkbox--custom-label"
                    htmlFor="career-interests-9"
                  >
                    <p>
                      <strong>
                        Sustainability, Environment & Natural Resources
                      </strong>
                      <br /> Do you see yourself focusing on conservation,
                      environmental improvement or solving social problems? Are
                      you passionate about the environment and affecting climate
                      change?{" "}
                      <a
                        href="https://career.asu.edu/sustainability-environmental-natural-resources"
                        target="_blank"
                        onClick={(ev) => {
                          // ev.preventDefault();
                          eventLog(
                            axios,
                            userId,
                            "2-Sustainability-Environment-Natural-Resources"
                          );
                        }}
                        rel="noopener noreferrer"
                        className="colored-link ml-1"
                      >
                        Learn more
                      </a>
                    </p>
                  </label>
                </div>

                <button
                  disabled={
                    prevCompleted && CI.length !== 0 ? !"disabled" : "disabled"
                  }
                  type="button"
                  className="btn btn-secondary btn-rounded complete_milestone_btn"
                  onClick={submitButton}
                >
                  <span className="flaticon-tick-1 icon"></span> Complete
                  Milestone
                </button>
              </div>
            )}
            {completed ? <MilestoneCompleteMsg submit={resetData} /> : null}
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default CarrerInterest;
