import React, { useState, useContext, useRef, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import axios from "axios";

import Part1 from "./1.handShakeProfile";
import Part2 from "./2.carrerInterest";
import Part3 from "./3.readyToExploreCI";
import Part4 from "./4.upToDateResume";
import Part5 from "./5.onlinePresence";
import Part6 from "./6.experienceWithCI";
import Part7 from "./7.professionalNetwork";
import Part8 from "./8.afterGraduation";
import ResetModal from "./resetModal";
import { scrollToRef } from "../Shared/helperFunctions";
import { DataContext } from "../Shared/context";

const MainForm = (props) => {
  const data = useContext(DataContext);
  // const { status, user, token, setUser, setToken, alert } = data;
  const { status, user, token, alert } = data;
  const cardRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [show, setShow] = useState(false);
  const showHandler = (partNo) => {
    let part = "showPart" + partNo;
    setShow({
      [part]: !show[part],
    });
  };
  const resetHandler = () => {
    scrollToRef(cardRef);
    axios
      .get(
        process.env.REACT_APP_RESET_URL +
          "dev/sethistory?user=" +
          `${user}` +
          "&resetall=1",
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        data.resetHandler();
        // console.log(res, "resetall");
      })
      .catch((err) => {
        console.log(err, "resetError");
      });
  };
  useEffect(() => {
    if (
      data.part1 &&
      data.part2 &&
      data.part3 &&
      data.part4 &&
      data.part5 &&
      data.part6 &&
      data.part7 &&
      data.part8
    ) {
      data.setStatus("completed");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      data.setStatus("start");
    }
  }, [data]);
  // const tokenHandler = () => {
  //   let newToken = prompt("Enter the User ID", "user");
  //   setUser(newToken);
  //   setToken(newToken);
  //   console.log(newToken);
  // };
  return (
    <>
      {/* <h5>{window.myToken}</h5> */}
      {/* <button className="btn btn-primary" onClick={tokenHandler}>
        Click to Switch User
      </button> */}
      <article className="inner-padding">
        {/* {<h6 style={{ color: "red" }}>Current User is : {user}</h6>} */}
        <div className="content-box">
          {/* <div className="content-box--header" ref={cardRef}>
          <h4 className="content-box--title">My Career Milestones</h4>
          </div> */}
          <div className="content-box--body">
            <div className="card content-box--card">
              <div className="card-body content-box--card-body">
                <div className="career-milestones--header">
                  {alert === true ? (
                    <div className="career-milestones--alert " role="alert">
                      It lookes like you've made some changes. Previously
                      completed milestones have been affected and may need to be
                      updated.
                    </div>
                  ) : null}

                  {status === "start" || status === "" ? (
                    <div className="career-milestones--start">
                      <h4 className="content-box--body-title">
                        Are you career ready?
                      </h4>
                      <p>
                        {/* Complete all these milestones to prepare you for
                              <br /> after graduation. */}
                        Career Milestones provide you with easy to follow steps
                        that will prepare you for whatever your next steps will
                        be upon graduation.
                        <br /> Click on the arrow next to each milestone to get
                        started!
                      </p>
                    </div>
                  ) : null}

                  {status === "half" ? (
                    <div className="career-milestones--half">
                      <div className="row">
                        <div className="col-6">
                          <h4 className="content-box--body-title">
                            Halfway there!
                          </h4>
                          <p>
                            You are currently on the{" "}
                            <strong>Online Presence Career Milestone.</strong>
                          </p>
                          <p>Let's keep going!</p>
                        </div>
                        <div className="col-6">
                          <div className="progress mx-auto" data-value="50">
                            <span className="progress-left">
                              <span className="progress-bar border-gold"></span>
                            </span>
                            <span className="progress-right">
                              <span className="progress-bar border-gold"></span>
                            </span>
                            <div className="progress-value">
                              <div className="h2 font-weight-bold">
                                50<span>%</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {status === "completed" ? (
                    <div className="career-milestones--completed">
                      <div className="row align-items-center">
                        <div className="col-6">
                          <h4 className="content-box--body-title">
                            Congrats, you did it!
                          </h4>
                          <p className="mb-0">
                            You have completed all your Career Milestones.
                            <br /> Great job!
                          </p>
                        </div>
                        <div className="col-6">
                          <img
                            src="img/congrats-icon-green.jpg"
                            className="d-block mx-auto"
                            height="110"
                            alt="Pic"
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {status === "explore" ? (
                    <div className="career-milestones--explore">
                      <div className="row">
                        <div className="col-6">
                          <h4 className="content-box--body-title">
                            Ready to explore?
                          </h4>
                          <p>
                            You are currently on the <br />{" "}
                            <strong>
                              Exploration Career Interest Milestone.
                            </strong>
                          </p>
                        </div>
                        <div className="col-6">
                          <img
                            src="/img/globe-icon-blue.jpg"
                            width="132"
                            className="img-fluid d-block mx-auto"
                            alt="Pic"
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div
                  className="accordion career-milestones"
                  id="careerMilestones"
                >
                  <Part1 show={show} clicked={showHandler} />
                  <Part2 show={show} clicked={showHandler} />
                  <Part3 show={show} clicked={showHandler} />
                  <Part4 show={show} clicked={showHandler} />
                  <Part5 show={show} clicked={showHandler} />
                  <Part6 show={show} clicked={showHandler} />
                  <Part7 show={show} clicked={showHandler} />
                  <Part8 show={show} clicked={showHandler} />
                </div>
                <div className="text-right">
                  <a
                    href="/"
                    className="colored-link"
                    data-toggle="modal"
                    data-target="#ResetMyProgress"
                    // onClick={props.resetForm}
                    onClick={(e) => {
                      e.preventDefault();
                      setShowModal(true);
                    }}
                  >
                    Reset my progress
                  </a>
                </div>
                <CSSTransition
                  in={showModal}
                  timeout={500}
                  classNames="item"
                  unmountOnExit
                >
                  <ResetModal
                    resetForm={resetHandler}
                    show={showModal}
                    clicked={() => setShowModal(false)}
                  />
                </CSSTransition>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
};

export default MainForm;
