import React from "react";
import BackDrop from "../Shared/Backdrop/backdrop";

const resetmodel = (props) => {
  return (
    <>
      <BackDrop show={true} clicked={props.clicked} />
      <div
        className={
          true
            ? "modal fade career-milestones--modal show"
            : "modal fade career-milestones--modal"
        }
        id="ResetMyProgress"
        tabIndex="-1"
        style={{ display: "block", paddingRight: "17px" }}
        onClick={props.clicked}
      >
        <div className="modal-dialog modal-dialog-centered career-milestones--modal-dialogue">
          <div
            className="modal-content career-milestones--modal-content"
            style={{ zIndex: "2500" }}
          >
            <div className="modal-body career-milestones--modal-body">
              <div className="career-milestones--modal-text">
                <h4 className="career-milestones--modal-title">
                  Are you sure want to reset <br /> your progress?
                </h4>
                <p>
                  This cannot be redone and all completed <br /> milestones will
                  be reset.
                </p>
              </div>
              <div className="career-milestones--modal-actions">
                <button
                  type="button"
                  className="btn btn-muted"
                  data-dismiss="modal"
                  onClick={props.clicked}
                >
                  Cancel
                </button>
                <a
                  href="/"
                  type="button"
                  className="btn btn-primary"
                  style={{ color: "white" }}
                  onClick={(e) => {
                    e.preventDefault();
                    props.resetForm();
                  }}
                >
                  Reset Progress
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default resetmodel;
