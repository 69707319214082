import React, { useEffect, useContext, useState } from "react";
import axios from "axios";

import Modal from "./Shared/Modal/modal";
import Spinner from "./Shared/Spinner/spinner";
import MainForm from "./Form/mainForm";
import { DataContext } from "./Shared/context";
import { setFormStatus, setSelectedCarrInt } from "./Shared/helperFunctions";

function App() {
  // console.log("app.js");
  const [loading, setLoading] = useState(true);
  const action = useContext(DataContext);
  const {
    user,
    token,
    setToken,
    errMsg,
    setErrMsg,
    setStatus,
    setPart1,
    setPart2,
    setPart3,
    setPart4,
    setPart5,
    setPart6,
    setPart7,
    setPart8,
    setSelectedCI,
    setAlert,
  } = action;

  let form = <Spinner />;
  // const tokenHandler = () => {
  //   setErrMsg("");
  //   setLoading(true);
  //   let newToken = prompt("Enter the Token ID", "");
  //   setToken(newToken);
  //   // console.log(newToken);
  // };

  useEffect(() => {
    const checkForToken = () => {
      if (!!window.wm_asu_Token) {
        console.log(window.wm_asu_Token, "token check");
        setToken(window.wm_asu_Token);
        setErrMsg("");
        action.setUser(window.wm_asu_Token);
      } else {
        setTimeout(() => {
          checkForToken();
          // setLoading(false);
        }, 5000);
        setErrMsg("You are not logged in. Please Login first");
      }
    };
    checkForToken();
  }, [token, setErrMsg, setToken]);

  useEffect(() => {
    if (!!window.wm_asu_Token) {
      axios
        .get(
          "https://835lx0tbj3.execute-api.us-east-1.amazonaws.com/dev/gethistory?user=" +
            `${user}`,
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          if (!!JSON.parse(res.data).response) {
            if (JSON.parse(res.data).response.message === "jwt expired") {
              console.log(JSON.parse(res.data));
              setLoading(false);
              setErrMsg("Your token has been expired");
              return;
            } else if (JSON.parse(res.data).code === 201) {
              setLoading(false);
              setErrMsg(JSON.parse(res.data).response);
              return;
            }
          }
          console.log(JSON.parse(res.data));
          // console.log(JSON.parse(JSON.parse(res.data)), "all data");
          let filledParts;
          if (Object.keys(JSON.parse(res.data)).length === 0) {
            filledParts = [];
          } else {
            filledParts = JSON.parse(
              JSON.parse(res.data).responseData.data_value
            ).map((t) => {
              return t.question;
            });
            if (filledParts.length === 0) {
              setStatus("start");
            } else if (filledParts.length === 8) {
              setStatus("completed");
            } else if (filledParts.length === 3) {
              setStatus("explore");
            } else {
              setStatus("half");
            }
          }

          setFormStatus(
            setPart1,
            setPart2,
            setPart3,
            setPart4,
            setPart5,
            setPart6,
            setPart7,
            setPart8,
            filledParts
          );
          setSelectedCarrInt(res.data, setSelectedCI);
          setAlert(false);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          setErrMsg(err.message);
          console.log(err);
        });
    }
  }, [
    user,
    setStatus,
    setPart1,
    setPart2,
    setPart3,
    setPart4,
    setPart5,
    setPart6,
    setPart7,
    setPart8,
    setSelectedCI,
    token,
    setErrMsg,
    setAlert,
  ]);
  if (!loading && errMsg) {
    form = (
      <Modal show={errMsg}>
        <p style={{ color: "red" }}>{errMsg}.</p>
        {/* <button
          style={{
            border: "none",
            backgroundColor: "transparent",
            outline: "none",
          }}
          onClick={tokenHandler}
        >
          click to add a valid token
        </button> */}
      </Modal>
    );
  } else if (!loading && !errMsg) {
    form = <MainForm />;
  }

  return <>{form}</>;
}

export default App;
