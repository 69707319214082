export const scrollToRef = (ref) => {
  window.scrollTo(0, ref.current.offsetTop);
};
export const setFormStatus = (
  setPart1,
  setPart2,
  setPart3,
  setPart4,
  setPart5,
  setPart6,
  setPart7,
  setPart8,
  data
) => {
  if (data.includes("1")) {
    setPart1(true);
  } else {
    setPart1(false);
  }
  if (data.includes("2")) {
    setPart2(true);
  } else {
    setPart2(false);
  }
  if (data.includes("3")) {
    setPart3(true);
  } else {
    setPart3(false);
  }
  if (data.includes("4")) {
    setPart4(true);
  } else {
    setPart4(false);
  }
  if (data.includes("5")) {
    setPart5(true);
  } else {
    setPart5(false);
  }
  if (data.includes("6")) {
    setPart6(true);
  } else {
    setPart6(false);
  }
  if (data.includes("7")) {
    setPart7(true);
  } else {
    setPart7(false);
  }
  if (data.includes("8")) {
    setPart8(true);
  } else {
    setPart8(false);
  }
};

export const eventLog = (axios, userId, eventName, token) => {
  return axios(
    process.env.REACT_APP_EVENTLOG_URL +
      "dev/eventlog?user=" +
      `${userId}` +
      "&event_name=reset-1&event_value=" +
      `${eventName}` +
      "&origin=website",
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then((res) => {
      // console.log("event log Success");
    })
    .catch((err) => {
      // console.log("event Log fail");
    });
};

export const setSelectedCarrInt = (data, setSelectedCI) => {
  let carInt;
  if (Object.keys(JSON.parse(data)).length === 0) {
    carInt = [];
  } else {
    carInt = JSON.parse(JSON.parse(data).responseData.data_value)
      .map((t) => {
        // console.log(t.values);
        if (t.question === "2") {
          return t.values.split(",");
        } else return null;
      })
      .filter((v) => Array.isArray(v));
    // console.log(carInt.length);
    if (carInt.length > 0) {
      setSelectedCI(...carInt);
    } else {
      setSelectedCI([]);
    }
  }
};
