import React, { useContext } from "react";
import axios from "axios";
import { eventLog } from "../../Shared/helperFunctions";
import { DataContext } from "../../Shared/context";

const Event = (props) => {
  const data = useContext(DataContext);
  const { user: userId } = data;
  let eventName = "part3-" + props.title;
  return (
    <p>
      <strong>Find {props.title} Event</strong> <br /> ASU offers a variety of
      career and professional focused events. These opportunities ensure you are
      career ready with the skills and connections needed to take your next
      steps. From workshops focused on the skills needed for a successful job or
      internship search to career fairs that enable you to build key connections
      and everything in between - we guarantee there is a focused event that
      meets you career goals.{" "}
      <a
        href={props.link}
        target="_blank"
        onClick={(ev) => {
          // ev.preventDefault();
          eventLog(axios, userId, eventName);
        }}
        rel="noopener noreferrer"
        className="colored-link ml-1"
      >
        Learn more
      </a>
      .
    </p>
  );
};

export default Event;
