import React from "react";

const milestoneCompletedMsg = (props) => {
  return (
    <div className="milestone--completed" style={{ display: "block" }}>
      <div className="checkbox-group">
        <div className="form-group">
          <p>
            <strong>Congrats on completing a milestone!</strong>
            {/* <br /> */}
            {/* Congrats on completing a milestone! */}
          </p>
        </div>
      </div>
      <p>
        <strong>You have completed this milestone.</strong> <br />
        <a
          role="button"
          href="/"
          target="_blank"
          className="colored-link reset-milestone"
          onClick={(e) => {
            e.preventDefault();
            props.submit();
          }}
        >
          Reset my progress for this milestone.
        </a>
      </p>
    </div>
  );
};

export default milestoneCompletedMsg;
