import React, { useRef, useState, useContext, useEffect } from "react";
import axios from "axios";
import { scrollToRef } from "../Shared/helperFunctions";
import MilestoneCompleteMsg from "./milestoneCompletedMsg";
import { CSSTransition } from "react-transition-group";
import Tab from "./CIE-Tabs/tabs";
import MentorNetwork from "./CIE-Tabs/mentorNetwork";
import Organizations from "./CIE-Tabs/organizations";
import Event from "./CIE-Tabs/event";
import CareerAdvisor from "./CIE-Tabs/careerAdvisor";
import TabsHeader from "./CIE-Tabs/tabsHeader";
import TabContent from "./CIE-Tabs/tabContent";
import { DataContext } from "../Shared/context";

const ReadyToExploreCI = (props) => {
  const data = useContext(DataContext);
  const cardRef = useRef(null);
  // let prevCompleted = props.formStatus.part2.completed;
  // let completed = props.formStatus.part3.completed;

  const {
    CIE,
    setCIE,
    part3: completed,
    part2: prevCompleted,
    token,
    setAlert,
  } = data;
  const inputHandler = (e) => {
    if (CIE.includes(e.target.id)) {
      let updateCIE = [];
      updateCIE = CIE.filter((element) => {
        return e.target.id !== element;
      });
      setCIE(updateCIE);
    } else {
      setCIE(CIE.concat(e.target.id));
    }
  };
  // console.log(CIE, "CIE");
  // const updateTabs = data.selectedCI.reduce((a, b) => ((a[b] = false), a), {});

  // console.log(data.selectedCI, "check");
  const [tab, setTab] = useState([]);
  useEffect(() => {
    let tabs = {};
    for (let i = 0; i < data.selectedCI.length; i++) {
      if (i === 0) {
        tabs[data.selectedCI[i]] = true;
      } else {
        tabs[data.selectedCI[i]] = false;
      }
    }
    setTab(tabs);
  }, [data.selectedCI]);

  const toggleTabs = (tab) => {
    setTab({
      // ...updateTabs,
      [tab]: true,
    });
  };
  const submitData = () => {
    axios
      .get(
        process.env.REACT_APP_SUBMIT_URL +
          "dev/sethistory?user=" +
          `${data.user}` +
          "&index=3&values=" +
          `${CIE}` +
          "&type=add",
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        // console.log("save3", JSON.parse(res.data));
        scrollToRef(cardRef);
        data.setPart3(true);
        setAlert(false);
        // setTimeout(() => props.clicked(3), 1000);
        // submitHandler(props, 3, cardRef);
        // console.log(JSON.parse(res.data).responseData.data_value);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const resetData = () => {
    axios
      .get(
        process.env.REACT_APP_RESET_URL +
          "dev/sethistory?user=" +
          `${data.user}` +
          "&index=3&type=reset",
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        // console.log("reset3", JSON.parse(res.data));
        data.setPart3(false);
        data.setCIE([]);
        // submitHandler(props, 3, cardRef, "reset");
        // console.log(JSON.parse(res.data).responseData.data_value);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div
      className={
        "card career-milestones--card " + (completed ? "completed" : "")
      }
      id="Explore_Career_Interest"
      ref={cardRef}
    >
      <div
        className={
          "card-header " +
          (props.show["showPart3"] ? "" : "collapsed ") +
          (completed ? "completed" : "")
        }
        role="button"
        data-toggle="collapse"
        data-target="#milestone-3"
        aria-expanded="true"
        onClick={() => props.clicked(3)}
      >
        Ready to explore your career interest?
        {data.alert ? (
          <img
            src="img/alert-icon-maroon.jpg"
            height="20"
            style={{ right: "50px", position: "absolute" }}
            alt="Pic"
          />
        ) : null}
      </div>
      <CSSTransition
        in={props.show["showPart3"]}
        timeout={500}
        classNames="item"
        unmountOnExit
      >
        <div
          id="milestone-3"
          // className={"collapse " + (props.show["showPart3"] ? "show" : "")}
          data-parent="#careerMilestones"
        >
          <div className="card-body">
            {completed ? null : (
              <div className="status--not">
                {data.selectedCI.length === 0 ? (
                  <strong>
                    Uh oh! You must first complete an earlier milestone before
                    starting this milestone.
                  </strong>
                ) : (
                  <p>
                    {/* There are many ways to explore your career interests. Find
                    the ones right for you. You only need to choose one to
                    complete this milestone. */}
                    Ready to explore career interest: There are many ways to
                    explore your interests. Check out the options below tailored
                    to your career interest. Complete at least one option below
                    to complete this milestone.
                  </p>
                )}
                <ul
                  className="nav nav-tabs career-milestones--tabs"
                  role="tablist"
                >
                  {data.selectedCI.includes("career-interests-1") ? (
                    <TabsHeader
                      className={
                        tab["career-interests-1"]
                          ? "active career-milestones--tabs-link"
                          : "career-milestones--tabs-link"
                      }
                      clicked={() => toggleTabs("career-interests-1")}
                    >
                      Arts, Design & Performance
                    </TabsHeader>
                  ) : null}
                  {data.selectedCI.includes("career-interests-2") ? (
                    <TabsHeader
                      className={
                        tab["career-interests-2"]
                          ? "active career-milestones--tabs-link"
                          : "career-milestones--tabs-link"
                      }
                      clicked={() => toggleTabs("career-interests-2")}
                    >
                      Business
                    </TabsHeader>
                  ) : null}
                  {data.selectedCI.includes("career-interests-3") ? (
                    <TabsHeader
                      className={
                        tab["career-interests-3"]
                          ? "active career-milestones--tabs-link"
                          : "career-milestones--tabs-link"
                      }
                      clicked={() => toggleTabs("career-interests-3")}
                    >
                      Communications & Media
                    </TabsHeader>
                  ) : null}
                  {data.selectedCI.includes("career-interests-4") ? (
                    <TabsHeader
                      className={
                        tab["career-interests-4"]
                          ? "active career-milestones--tabs-link"
                          : "career-milestones--tabs-link"
                      }
                      clicked={() => toggleTabs("career-interests-4")}
                    >
                      Education
                    </TabsHeader>
                  ) : null}
                  {data.selectedCI.includes("career-interests-5") ? (
                    <TabsHeader
                      className={
                        tab["career-interests-5"]
                          ? "active career-milestones--tabs-link"
                          : "career-milestones--tabs-link"
                      }
                      clicked={() => toggleTabs("career-interests-5")}
                    >
                      Entrepreneurship
                    </TabsHeader>
                  ) : null}
                  {data.selectedCI.includes("career-interests-6") ? (
                    <TabsHeader
                      className={
                        tab["career-interests-6"]
                          ? "active career-milestones--tabs-link"
                          : "career-milestones--tabs-link"
                      }
                      clicked={() => toggleTabs("career-interests-6")}
                    >
                      Health & Wellness
                    </TabsHeader>
                  ) : null}

                  {data.selectedCI.includes("career-interests-7") ? (
                    <TabsHeader
                      className={
                        tab["career-interests-7"]
                          ? "active career-milestones--tabs-link"
                          : "career-milestones--tabs-link"
                      }
                      clicked={() => toggleTabs("career-interests-7")}
                    >
                      Public, Social & Human Services
                    </TabsHeader>
                  ) : null}
                  {data.selectedCI.includes("career-interests-8") ? (
                    <TabsHeader
                      className={
                        tab["career-interests-8"]
                          ? "active career-milestones--tabs-link"
                          : "career-milestones--tabs-link"
                      }
                      clicked={() => toggleTabs("career-interests-8")}
                    >
                      Science, Technology, Engineering & Mathematics
                    </TabsHeader>
                  ) : null}
                  {data.selectedCI.includes("career-interests-9") ? (
                    <TabsHeader
                      className={
                        tab["career-interests-9"]
                          ? "active career-milestones--tabs-link"
                          : "career-milestones--tabs-link"
                      }
                      clicked={() => toggleTabs("career-interests-9")}
                    >
                      Sustainability, Environment & Natural Resources
                    </TabsHeader>
                  ) : null}
                </ul>
                <div className="tab-content career-milestones--tabs-content">
                  {data.selectedCI.includes("career-interests-1") ? (
                    <Tab
                      className={
                        tab["career-interests-1"]
                          ? "tab-pane active  career-milestones--tabs-pane"
                          : "tab-pane career-milestones--tabs-pane"
                      }
                      id="career-interest-tab-1"
                    >
                      <TabContent
                        htmlFor="milestone-3-check-1-1"
                        inputHandler={inputHandler}
                        checked={CIE.includes("milestone-3-check-1-1")}
                        id="milestone-3-check-1-1"
                      >
                        <MentorNetwork
                          title="Arts, Design & Performance"
                          link="https://mentorship.asu.edu/hub/arizonastate/group/arts-design--performance---career-interest-community/about"
                        />
                      </TabContent>
                      <TabContent
                        htmlFor="milestone-3-check-1-2"
                        inputHandler={inputHandler}
                        checked={CIE.includes("milestone-3-check-1-2")}
                        id="milestone-3-check-1-2"
                      >
                        <Organizations
                          title="Arts, Design & Performance"
                          link="https://career.asu.edu/interested-arts-design-and-performance-get-involved"
                        />
                      </TabContent>
                      <TabContent
                        htmlFor="milestone-3-check-1-3"
                        inputHandler={inputHandler}
                        checked={CIE.includes("milestone-3-check-1-3")}
                        id="milestone-3-check-1-3"
                      >
                        <Event
                          title="Arts, Design & Performance"
                          link="https://asu.joinhandshake.com/events?page=1&per_page=25&sort_direction=asc&sort_column=default&ref=content-type-nav&type=Event&added_institution_labels%5B%5D=219229"
                        />
                      </TabContent>
                      <TabContent
                        htmlFor="milestone-3-check-1-4"
                        inputHandler={inputHandler}
                        checked={CIE.includes("milestone-3-check-1-4")}
                        id="milestone-3-check-1-4"
                      >
                        <CareerAdvisor
                          title="Arts, Design & Performance"
                          link="https://career.asu.edu/arts-design-and-performance"
                        />
                      </TabContent>
                    </Tab>
                  ) : null}
                  {data.selectedCI.includes("career-interests-2") ? (
                    <Tab
                      className={
                        tab["career-interests-2"]
                          ? "tab-pane active  career-milestones--tabs-pane"
                          : "tab-pane career-milestones--tabs-pane"
                      }
                      id="career-interest-tab-2"
                    >
                      <TabContent
                        htmlFor="milestone-3-check-2-1"
                        inputHandler={inputHandler}
                        checked={CIE.includes("milestone-3-check-2-1")}
                        id="milestone-3-check-2-1"
                      >
                        <MentorNetwork
                          title="Business"
                          link="https://mentorship.asu.edu/hub/arizonastate/group/business---career-interest-community/about"
                        />
                      </TabContent>
                      <TabContent
                        htmlFor="milestone-3-check-2-2"
                        inputHandler={inputHandler}
                        checked={CIE.includes("milestone-3-check-2-2")}
                        id="milestone-3-check-2-2"
                      >
                        <Organizations
                          title="Business"
                          link="https://career.asu.edu/interested-business-get-involved"
                        />
                      </TabContent>
                      <TabContent
                        htmlFor="milestone-3-check-2-3"
                        inputHandler={inputHandler}
                        checked={CIE.includes("milestone-3-check-2-3")}
                        id="milestone-3-check-2-3"
                      >
                        <Event
                          title="Business"
                          link="https://asu.joinhandshake.com/events?page=1&per_page=25&sort_direction=asc&sort_column=default&type=Event&added_institution_labels%5B%5D=219230"
                        />
                      </TabContent>
                      <TabContent
                        htmlFor="milestone-3-check-2-4"
                        inputHandler={inputHandler}
                        checked={CIE.includes("milestone-3-check-2-4")}
                        id="milestone-3-check-2-4"
                      >
                        <CareerAdvisor
                          title="Business"
                          link="https://career.asu.edu/business"
                        />
                      </TabContent>
                    </Tab>
                  ) : null}
                  {data.selectedCI.includes("career-interests-3") ? (
                    <Tab
                      className={
                        tab["career-interests-3"]
                          ? "tab-pane active  career-milestones--tabs-pane"
                          : "tab-pane career-milestones--tabs-pane"
                      }
                      id="career-interest-tab-3"
                    >
                      <TabContent
                        htmlFor="milestone-3-check-3-1"
                        inputHandler={inputHandler}
                        checked={CIE.includes("milestone-3-check-3-1")}
                        id="milestone-3-check-3-1"
                      >
                        <MentorNetwork
                          title="Communications & Media"
                          link="https://mentorship.asu.edu/hub/arizonastate/group/communications--media---career-interest-community/about"
                        />
                      </TabContent>
                      <TabContent
                        htmlFor="milestone-3-check-3-2"
                        inputHandler={inputHandler}
                        checked={CIE.includes("milestone-3-check-3-2")}
                        id="milestone-3-check-3-2"
                      >
                        <Organizations
                          title="Communications & Media"
                          link="https://career.asu.edu/communications-and-media"
                        />
                      </TabContent>
                      <TabContent
                        htmlFor="milestone-3-check-3-3"
                        inputHandler={inputHandler}
                        checked={CIE.includes("milestone-3-check-3-3")}
                        id="milestone-3-check-3-3"
                      >
                        <Event
                          title="Communications & Media"
                          link="https://asu.joinhandshake.com/events?page=1&per_page=25&sort_direction=asc&sort_column=default&type=Event&added_institution_labels%5B%5D=225247"
                        />
                      </TabContent>
                      <TabContent
                        htmlFor="milestone-3-check-3-4"
                        inputHandler={inputHandler}
                        checked={CIE.includes("milestone-3-check-3-4")}
                        id="milestone-3-check-3-4"
                      >
                        <CareerAdvisor
                          title="Communications & Media"
                          link="https://career.asu.edu/communications-and-media"
                        />
                      </TabContent>
                    </Tab>
                  ) : null}
                  {data.selectedCI.includes("career-interests-4") ? (
                    <Tab
                      className={
                        tab["career-interests-4"]
                          ? "tab-pane active  career-milestones--tabs-pane"
                          : "tab-pane career-milestones--tabs-pane"
                      }
                      id="career-interest-tab-4"
                    >
                      <TabContent
                        htmlFor="milestone-3-check-4-1"
                        inputHandler={inputHandler}
                        checked={CIE.includes("milestone-3-check-4-1")}
                        id="milestone-3-check-4-1"
                      >
                        <MentorNetwork
                          title="Education"
                          link="https://mentorship.asu.edu/hub/arizonastate/group/education---career-interest-community/about"
                        />
                      </TabContent>
                      <TabContent
                        htmlFor="milestone-3-check-4-2"
                        inputHandler={inputHandler}
                        checked={CIE.includes("milestone-3-check-4-2")}
                        id="milestone-3-check-4-2"
                      >
                        <Organizations
                          title="Education"
                          link="https://career.asu.edu/education"
                        />
                      </TabContent>
                      <TabContent
                        htmlFor="milestone-3-check-4-3"
                        inputHandler={inputHandler}
                        checked={CIE.includes("milestone-3-check-4-3")}
                        id="milestone-3-check-4-3"
                      >
                        <Event
                          title="Education"
                          link="https://asu.joinhandshake.com/events?page=1&per_page=25&sort_direction=asc&sort_column=default&type=Event&added_institution_labels%5B%5D=219239"
                        />
                      </TabContent>
                      <TabContent
                        htmlFor="milestone-3-check-4-4"
                        inputHandler={inputHandler}
                        checked={CIE.includes("milestone-3-check-4-4")}
                        id="milestone-3-check-4-4"
                      >
                        <CareerAdvisor
                          title="Education"
                          link="https://career.asu.edu/education"
                        />
                      </TabContent>
                    </Tab>
                  ) : null}
                  {data.selectedCI.includes("career-interests-5") ? (
                    <Tab
                      className={
                        tab["career-interests-5"]
                          ? "tab-pane active  career-milestones--tabs-pane"
                          : "tab-pane career-milestones--tabs-pane"
                      }
                      id="career-interest-tab-5"
                    >
                      <TabContent
                        htmlFor="milestone-3-check-5-1"
                        inputHandler={inputHandler}
                        checked={CIE.includes("milestone-3-check-5-1")}
                        id="milestone-3-check-5-1"
                      >
                        <MentorNetwork
                          title="Entrepreneurship"
                          link="https://mentorship.asu.edu/hub/arizonastate/group/entrepreneurship---career-interest-community/about"
                        />
                      </TabContent>
                      <TabContent
                        htmlFor="milestone-3-check-5-2"
                        inputHandler={inputHandler}
                        checked={CIE.includes("milestone-3-check-5-2")}
                        id="milestone-3-check-5-2"
                      >
                        <Organizations
                          title="Entrepreneurship"
                          link="https://career.asu.edu/interested-entrepreneurship-get-involved"
                        />
                      </TabContent>
                      <TabContent
                        htmlFor="milestone-3-check-5-3"
                        inputHandler={inputHandler}
                        checked={CIE.includes("milestone-3-check-5-3")}
                        id="milestone-3-check-5-3"
                      >
                        <Event
                          title="Entrepreneurship"
                          link="https://asu.joinhandshake.com/events?page=1&per_page=25&sort_direction=asc&sort_column=default&type=Event&added_institution_labels%5B%5D=219233"
                        />
                      </TabContent>
                      <TabContent
                        htmlFor="milestone-3-check-5-4"
                        inputHandler={inputHandler}
                        checked={CIE.includes("milestone-3-check-5-4")}
                        id="milestone-3-check-5-4"
                      >
                        <CareerAdvisor
                          title="Entrepreneurship"
                          link="https://career.asu.edu/entrepreneurship"
                        />
                      </TabContent>
                    </Tab>
                  ) : null}
                  {data.selectedCI.includes("career-interests-6") ? (
                    <Tab
                      className={
                        tab["career-interests-6"]
                          ? "tab-pane active  career-milestones--tabs-pane"
                          : "tab-pane career-milestones--tabs-pane"
                      }
                      id="career-interest-tab-6"
                    >
                      <TabContent
                        htmlFor="milestone-3-check-6-1"
                        inputHandler={inputHandler}
                        checked={CIE.includes("milestone-3-check-6-1")}
                        id="milestone-3-check-6-1"
                      >
                        <MentorNetwork
                          title="Health & Wellness"
                          link="https://mentorship.asu.edu/hub/arizonastate/group/health--wellness---career-interest-community/about"
                        />
                      </TabContent>
                      <TabContent
                        htmlFor="milestone-3-check-6-2"
                        inputHandler={inputHandler}
                        checked={CIE.includes("milestone-3-check-6-2")}
                        id="milestone-3-check-6-2"
                      >
                        <Organizations
                          title="Health & Wellness"
                          link="https://career.asu.edu/interested-health-and-wellness-get-involved"
                        />
                      </TabContent>
                      <TabContent
                        htmlFor="milestone-3-check-6-3"
                        inputHandler={inputHandler}
                        checked={CIE.includes("milestone-3-check-6-3")}
                        id="milestone-3-check-6-3"
                      >
                        <Event
                          title="Health & Wellness"
                          link="https://asu.joinhandshake.com/events?page=1&per_page=25&sort_direction=asc&sort_column=default&type=Event&added_institution_labels%5B%5D=219236"
                        />
                      </TabContent>
                      <TabContent
                        htmlFor="milestone-3-check-6-4"
                        inputHandler={inputHandler}
                        checked={CIE.includes("milestone-3-check-6-4")}
                        id="milestone-3-check-6-4"
                      >
                        <CareerAdvisor
                          title="Health & Wellness"
                          link="https://career.asu.edu/health-and-wellness"
                        />
                      </TabContent>
                    </Tab>
                  ) : null}
                  {data.selectedCI.includes("career-interests-7") ? (
                    <Tab
                      className={
                        tab["career-interests-7"]
                          ? "tab-pane active  career-milestones--tabs-pane"
                          : "tab-pane career-milestones--tabs-pane"
                      }
                      id="career-interest-tab-7"
                    >
                      <TabContent
                        htmlFor="milestone-3-check-7-1"
                        inputHandler={inputHandler}
                        checked={CIE.includes("milestone-3-check-7-1")}
                        id="milestone-3-check-7-1"
                      >
                        <MentorNetwork
                          title="Public, Social & Human Services"
                          link="https://mentorship.asu.edu/hub/arizonastate/group/public-social-and-human-services---career-interest-community/about"
                        />
                      </TabContent>
                      <TabContent
                        htmlFor="milestone-3-check-7-2"
                        inputHandler={inputHandler}
                        checked={CIE.includes("milestone-3-check-7-2")}
                        id="milestone-3-check-7-2"
                      >
                        <Organizations
                          title="Public, Social & Human Services"
                          link="https://career.asu.edu/interested-public-social-and-human-services-get-involved"
                        />
                      </TabContent>
                      <TabContent
                        htmlFor="milestone-3-check-7-3"
                        inputHandler={inputHandler}
                        checked={CIE.includes("milestone-3-check-7-3")}
                        id="milestone-3-check-7-3"
                      >
                        <Event
                          title="Public, Social & Human Services"
                          link="https://asu.joinhandshake.com/events?page=1&per_page=25&sort_direction=asc&sort_column=default&type=Event&added_institution_labels%5B%5D=219242"
                        />
                      </TabContent>
                      <TabContent
                        htmlFor="milestone-3-check-7-4"
                        inputHandler={inputHandler}
                        checked={CIE.includes("milestone-3-check-7-4")}
                        id="milestone-3-check-7-4"
                      >
                        <CareerAdvisor
                          title="Public, Social & Human Services"
                          link="https://career.asu.edu/public-social-and-human-services"
                        />
                      </TabContent>
                    </Tab>
                  ) : null}
                  {data.selectedCI.includes("career-interests-8") ? (
                    <Tab
                      className={
                        tab["career-interests-8"]
                          ? "tab-pane active  career-milestones--tabs-pane"
                          : "tab-pane career-milestones--tabs-pane"
                      }
                      id="career-interest-tab-8"
                    >
                      <TabContent
                        htmlFor="milestone-3-check-8-1"
                        inputHandler={inputHandler}
                        checked={CIE.includes("milestone-3-check-8-1")}
                        id="milestone-3-check-8-1"
                      >
                        <MentorNetwork
                          title="Science, Technology, Engineering & Mathematics"
                          link="https://mentorship.asu.edu/hub/arizonastate/group/sustainability-environmental--natural-resources---career-interest-community/about"
                        />
                      </TabContent>
                      <TabContent
                        htmlFor="milestone-3-check-8-2"
                        inputHandler={inputHandler}
                        checked={CIE.includes("milestone-3-check-8-2")}
                        id="milestone-3-check-8-2"
                      >
                        <Organizations
                          title="Science, Technology, Engineering & Mathematics"
                          link="https://career.asu.edu/interested-stem-get-involved"
                        />
                      </TabContent>
                      <TabContent
                        htmlFor="milestone-3-check-8-3"
                        inputHandler={inputHandler}
                        checked={CIE.includes("milestone-3-check-8-3")}
                        id="milestone-3-check-8-3"
                      >
                        <Event
                          title="Science, Technology, Engineering & Mathematics"
                          link="https://asu.joinhandshake.com/events?page=1&per_page=25&sort_direction=asc&sort_column=default&type=Event&added_institution_labels%5B%5D=219244"
                        />
                      </TabContent>
                      <TabContent
                        htmlFor="milestone-3-check-8-4"
                        inputHandler={inputHandler}
                        checked={CIE.includes("milestone-3-check-8-4")}
                        id="milestone-3-check-8-4"
                      >
                        <CareerAdvisor
                          title="Science, Technology, Engineering & Mathematics"
                          link="https://career.asu.edu/stem"
                        />
                      </TabContent>
                    </Tab>
                  ) : null}
                  {data.selectedCI.includes("career-interests-9") ? (
                    <Tab
                      className={
                        tab["career-interests-9"]
                          ? "tab-pane active  career-milestones--tabs-pane"
                          : "tab-pane career-milestones--tabs-pane"
                      }
                      id="career-interest-tab-9"
                    >
                      <TabContent
                        htmlFor="milestone-3-check-9-1"
                        inputHandler={inputHandler}
                        checked={CIE.includes("milestone-3-check-9-1")}
                        id="milestone-3-check-9-1"
                      >
                        <MentorNetwork
                          title="Sustainability, Environment & Natural Resources"
                          link="https://mentorship.asu.edu/hub/arizonastate/group/sustainability-environmental--natural-resources---career-interest-community/about"
                        />
                      </TabContent>
                      <TabContent
                        htmlFor="milestone-3-check-9-2"
                        inputHandler={inputHandler}
                        checked={CIE.includes("milestone-3-check-9-2")}
                        id="milestone-3-check-9-2"
                      >
                        <Organizations
                          title="Sustainability, Environment & Natural Resources"
                          link="https://career.asu.edu/interested-sustainability-get-involved"
                        />
                      </TabContent>
                      <TabContent
                        htmlFor="milestone-3-check-9-3"
                        inputHandler={inputHandler}
                        checked={CIE.includes("milestone-3-check-9-3")}
                        id="milestone-3-check-9-3"
                      >
                        <Event
                          title="Sustainability, Environment & Natural Resources"
                          link="https://asu.joinhandshake.com/events?page=1&per_page=25&sort_direction=asc&sort_column=default&type=Event&added_institution_labels%5B%5D=219247"
                        />
                      </TabContent>
                      <TabContent
                        htmlFor="milestone-3-check-9-4"
                        inputHandler={inputHandler}
                        checked={CIE.includes("milestone-3-check-9-4")}
                        id="milestone-3-check-9-4"
                      >
                        <CareerAdvisor
                          title="Sustainability, Environment & Natural Resources"
                          link="https://career.asu.edu/sustainability-environmental-natural-resources"
                        />
                      </TabContent>
                    </Tab>
                  ) : null}
                </div>
                {data.selectedCI.length === 0 ? null : (
                  <button
                    disabled={
                      prevCompleted && CIE.length !== 0
                        ? !"disabled"
                        : "disabled"
                    }
                    type="button"
                    className="btn btn-secondary btn-rounded complete_milestone_btn"
                    onClick={submitData}
                  >
                    <span className="flaticon-tick-1 icon"></span> I'm done
                    exploring
                  </button>
                )}
              </div>
            )}

            {completed ? <MilestoneCompleteMsg submit={resetData} /> : null}
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default ReadyToExploreCI;
