import React from "react";

const TabsHeader = (props) => {
  return (
    <li className="list-inline-item" role="presentation">
      <a
        className={props.className}
        data-toggle="tab"
        href="/"
        onClick={(ev) => {
          ev.preventDefault();
          props.clicked();
        }}
        role="tab"
      >
        {props.children}
      </a>
    </li>
  );
};

export default TabsHeader;
